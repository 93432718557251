<template>
  <ModalLayout
    v-model="isOpen"
    class-content="AppConnectionProjectsDialog"
    no-header
    no-footer
    :max-width="630"
    @click:outside="isOpen = false"
    @click:close="isOpen = false"
  >
    <div class="AppConnectionProjectsDialog__body">
      <div class="AppConnectionProjectsDialog__title">
        {{ $t('connection.AppConnectionProjects',{ appConnectionName: appConnection.name }) }}
      </div>
      <v-list style="max-height: 400px; overflow: auto;">
        <v-list-item
          v-for="project in appConnectionProjects"
          :key="project.id"
          class="AppConnectionProjectsDialog__row"
          :to="{ name: 'ProjectDashboard', params: { projectId: project.id } }"
          @click="isOpen = false"
        >
          <div class="AppConnectionProjectsDialog__cell text-truncate">
            {{ project.name }}
          </div>
          <div class="AppConnectionProjectsDialog__cell">
            <ConnectionStatus
              class="flex-shrink-0"
              style="margin-right: 6px"
              :connection-id="project.hawserID"
              :connection-name="project.connectionName"
              :last-incoming-pong="project.lastIncomingPong"
              :last-outgoing-ping="project.lastOutgoingPing"
              show-status-name
              tooltip-position="top"
              platform-name="frigate"
            />
          </div>
        </v-list-item>
      </v-list>
    </div>
  </ModalLayout>
</template>

<script>
import { ConnectionStatus, ModalLayout } from '@hexway/shared-front'

export default {
  name: 'AppConnectionProjectsDialog',

  components: {
    ConnectionStatus,
    ModalLayout,
  },

  inheritAttrs: false,

  props: {
    dialogInstance: { type: Object, required: true },
    appConnection: { type: Object, required: true },
    appConnectionProjects: { type: Array, required: true },
  },

  computed: {
    isOpen: {
      get() { return this.dialogInstance.isOpen },
      set(isOpen) { this.dialogInstance.onDialogModelInput(isOpen) },
    },
  },

}
</script>

<style lang="sass" scoped>
.AppConnectionProjectsDialog
  &__body
    display: flex
    flex-flow: column

  &__title
    font-size: 24px
    font-weight: 500
    line-height: 32px
    max-width: 500px
    word-break: break-all

  &__row
    display: flex
    flex-flow: row nowrap
    width: 100%
    height: 64px
    cursor: pointer
    color: #41415A
    font-weight: 400
    font-size: 14px
    line-height: 20px
    letter-spacing: 0.005em
    padding-left: 16px

    &:hover, &:active, &:focus
      background: #F5F5F9

  &__cell
    display: flex
    flex: 1
    font-size: 14px
    padding: 8px 3px
    justify-content: flex-start
    align-items: center

</style>
